<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <strong>{{ $lang.resource.crud.create }}</strong>
        </CCardHeader>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <CRow>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{ 'form-group--error': $v.resource.firstname.$error }"
                >
                  <label class="form__label"
                  >{{ $lang.resource.form.firstname }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.firstname"
                      class="form-control"
                      v-model="resource.firstname"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.resource.firstname.$error &&
                      !$v.resource.firstname.required
                    "
                  >{{ $lang.resource.validation.required.firstname }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.lastname }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.lastname"
                      class="form-control"
                      v-model="resource.lastname"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group">
                  <label class="form__label"
                  >{{ $lang.resource.form.nickname }}
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.nickname"
                      class="form-control"
                      v-model="resource.nickname"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.resourceType }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="resourceTypeOptions"
                      v-model="resource.resourceType"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.resourceType.$error &&
                      !$v.resource.resourceType.required
                    "
                  >
                    {{ $lang.resource.validation.required.resourceType }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.speackWithClient }} </label
                  >&nbsp;&nbsp;
                  <input
                      type="checkbox"
                      :placeholder="$lang.resource.form.speackWithClient"
                      :value="1"
                      @change="changedSpeack"
                      v-model="resource.speackWithClient"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.isRemote }} </label
                  >&nbsp;&nbsp;
                  <input
                      type="checkbox"
                      :placeholder="$lang.resource.form.isRemote"
                      :value="1"
                      @change="changedRemote"
                      v-model="resource.isRemote"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.experience }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="experienceOptions"
                      v-model="resource.experience"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.experience.$error &&
                      !$v.resource.experience.required
                    "
                  >
                    {{ $lang.resource.validation.required.experience }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.avaibility }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="availabilityOptions"
                      v-model="resource.avaibility"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.avaibility.$error &&
                      !$v.resource.avaibility.required
                    "
                  >
                    {{ $lang.resource.validation.required.avaibility }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.currency }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="currencyOptions"
                      v-model="resource.currency"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.currency.$error &&
                      !$v.resource.currency.required
                    "
                  >
                    {{ $lang.resource.validation.required.currency }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.resource.fromPricePerHours.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.resource.form.fromPricePerHours }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.fromPricePerHours"
                      class="form-control"
                      v-model="resource.fromPricePerHours"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.resource.fromPricePerHours.$error &&
                      !$v.resource.fromPricePerHours.required
                    "
                  >{{
                      $lang.resource.validation.required.fromPricePerHours
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.resource.toPricePerHours.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.resource.form.toPricePerHours }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.toPricePerHours"
                      class="form-control"
                      v-model="resource.toPricePerHours"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.resource.toPricePerHours.$error &&
                      !$v.resource.toPricePerHours.required
                    "
                  >{{
                      $lang.resource.validation.required.toPricePerHours
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.resource.fromPricePerMonth.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.resource.form.fromPricePerMonth }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.fromPricePerMonth"
                      class="form-control"
                      v-model="resource.fromPricePerMonth"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.resource.fromPricePerMonth.$error &&
                      !$v.resource.fromPricePerMonth.required
                    "
                  >{{
                      $lang.resource.validation.required.fromPricePerMonth
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.resource.toPricePerMonth.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.resource.form.toPricePerMonth }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.toPricePerMonth"
                      class="form-control"
                      v-model="resource.toPricePerMonth"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.resource.toPricePerMonth.$error &&
                      !$v.resource.toPricePerMonth.required
                    "
                  >{{
                      $lang.resource.validation.required.toPricePerMonth
                    }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.skills }}
                    <required_span/>
                  </label>
                  <v-select
                      multiple
                      :options="skillsOptions"
                      v-model="resource.skills"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.skills.$error && !$v.resource.skills.required
                    "
                  >
                    {{ $lang.resource.validation.required.skills }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div
                    class="form-group"
                    :class="{
                    'form-group--error': $v.resource.skillOthers.$error,
                  }"
                >
                  <label class="form__label"
                  >{{ $lang.resource.form.skillOthers }}
                    <required_span/>
                  </label>
                  <input
                      type="text"
                      :placeholder="$lang.resource.form.skillOthers"
                      class="form-control"
                      v-model="resource.skillOthers"
                  />
                  <small
                      class="error"
                      v-if="
                      $v.resource.skillOthers.$error &&
                      !$v.resource.skillOthers.required
                    "
                  >{{ $lang.resource.validation.required.skillOthers }}</small
                  >
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.languages }}
                    <required_span/>
                  </label>
                  <v-select
                      multiple
                      :options="languageOptions"
                      v-model="resource.languages"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.languages.$error &&
                      !$v.resource.languages.required
                    "
                  >
                    {{ $lang.resource.validation.required.languages }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.country }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="countryOptions"
                      v-model="resource.country"
                  ></v-select>
                  <small
                      class="error"
                      v-if="
                      $v.resource.country.$error &&
                      !$v.resource.country.required
                    "
                  >
                    {{ $lang.resource.validation.required.country }}
                  </small>
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.common.form.isActive }} </label
                  >&nbsp;&nbsp;
                  <input
                      type="checkbox"
                      :placeholder="$lang.common.form.isActive"
                      :value="1"
                      @change="changedIsActive"
                      v-model="resource.isActive"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <div class="form-group" :class="{ 'form-group--error': '' }">
                  <label class="form__label"
                  >{{ $lang.resource.form.user }}
                    <required_span/>
                  </label>
                  <v-select
                      :options="userOptions"
                      v-model="resource.user"
                  ></v-select>
                  <small
                      class="error"
                      v-if="$v.resource.user.$error && !$v.resource.user.required"
                  >
                    {{ $lang.resource.validation.required.user }}
                  </small>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
            <CButton color="danger" size="sm" @click="cancel">
              <CIcon name="cil-ban"/>
              {{ $lang.buttons.general.crud.cancel }}
            </CButton>
          </CCardFooter>
        </CForm>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  resource,
  resourcetype,
  experience,
  availability,
  currencytype,
  users,
  skills,
  language,
  country,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import moment from "moment";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "ResourceCreate",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      submitted: false,
      err_msg: "",
      date: "",
      dismissCountDown: 0,
      remoteWorkOptions: [],
      skillsOptions: [],
      industryOptions: [],
      subIndustryOptions: [],
      currencyOptions: [],
      durationOptions: [],
      getSkills: [],
      getLanguage: [],
      resourceTypeOptions: [],
      experienceOptions: [],
      availabilityOptions: [],
      languageOptions: [],
      countryOptions: [],
      self: this,
      module: resource,
      moduleCurrency: currencytype,
      moduleSkills: skills,
      moduleresourceType: resourcetype,
      moduleexperience: experience,
      moduleavailability: availability,
      modulelanguage: language,
      modulecountry: country,
      moduleUser: users,
      userOptions: [],
      resource: {
        firstname: "",
        lastname: "",
        nickname: "",
        resourceType: "",
        experience: "",
        avaibility: "",
        currency: "",
        fromPricePerHours: "",
        toPricePerHours: "",
        fromPricePerMonth: "",
        toPricePerMonth: "",
        skills: "",
        skillOthers: "",
        languages: "",
        country: "",
        isActive: 1,
        isRemote: 1,
        speackWithClient: "Yes",
        user: "",
      },
    };
  },
  validations: {
    resource: {
      firstname: {
        required,
      },
      resourceType: {
        required,
      },
      experience: {
        required,
      },
      avaibility: {
        required,
      },
      currency: {
        required,
      },
      fromPricePerHours: {
        required,
      },
      toPricePerHours: {
        required,
      },
      fromPricePerMonth: {
        required,
      },
      toPricePerMonth: {
        required,
      },
      skills: {
        required,
      },
      skillOthers: {
        required,
      },
      languages: {
        required,
      },
      country: {
        required,
      },
      user: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.remoteWorkOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );
    self.dismissCountDown = 0;
    // self.sendAlert();
    axios.get(this.listUrlApi(this.moduleexperience)).then((response) => {
      response.data.data.map(function (value, key) {
        self.experienceOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleresourceType)).then((response) => {
      response.data.data.map(function (value, key) {
        self.resourceTypeOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleavailability)).then((response) => {
      response.data.data.map(function (value, key) {
        self.availabilityOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleSkills)).then((response) => {
      response.data.data.map(function (value, key) {
        self.skillsOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.username});
      });
    });
    axios.get(this.listUrlApi(this.moduleCurrency)).then((response) => {
      response.data.data.map(function (value, key) {
        self.currencyOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.modulelanguage)).then((response) => {
      response.data.data.map(function (value, key) {
        self.languageOptions.push({value: value.id, label: value.title});
      });
    });
    axios.get(this.listUrlApi(this.modulecountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.resource.isActive = 1;
      } else {
        self.resource.isActive = 0;
      }
    },
    changedRemote(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.resource.isRemote = 1;
      } else {
        self.resource.isRemote = 0;
      }
    },
    changedSpeack(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.resource.speackWithClient = "Yes";
      } else {
        self.resource.speackWithClient = "No";
      }
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    onSubmit() {
      let self = this;
      this.$v.resource.$touch();
      if (this.$v.resource.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        setTimeout(() => {
          let self = this;
          let getSkills = [];
          let getLanguage = [];
          self.resource.skills.map(function (value, key) {
            getSkills[key] = value.label;
          });
          self.resource.languages.map(function (value, key) {
            getLanguage[key] = value.label;
          });
          const formData = new FormData();
          formData.append("firstName", self.resource.firstname);
          formData.append("isActive", self.resource.isActive);
          formData.append("isRemote", self.resource.isRemote);
          formData.append("lastName", self.resource.lastname);
          formData.append("nickname", self.resource.nickname);
          formData.append("resourceTypeId", self.resource.resourceType.value);
          formData.append("speackWithClient", self.resource.speackWithClient);
          formData.append("experienceId", self.resource.experience.value);
          formData.append("avaibilityId", self.resource.avaibility.value);
          formData.append("userId", self.resource.user.value);
          formData.append("currencyId", self.resource.currency.value);
          formData.append("fromPricePerHours", self.resource.fromPricePerHours);
          formData.append("toPricePerHours", self.resource.toPricePerHours);
          formData.append("fromPricePerMonth", self.resource.fromPricePerMonth);
          formData.append("toPricePerMonth", self.resource.toPricePerMonth);
          formData.append("skills", getSkills);
          formData.append("skillOthers", self.resource.skillOthers);
          formData.append("languages", getLanguage);
          formData.append("countryId", self.resource.country.value);
          axios
              .post(this.createUrlWeb(this.module), formData)
              .then(function (response) {
                self.submitted = false; //Enable Button
                if (response.data.code === 200) {
                  localStorage.setItem("showAlert", "This is my alert");
                  self.sendAlert();
                  self.$router.push({path: "/resource"});
                } else {
                  self.err_msg = response.data.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                self.submitted = false; //Enable Button
              });
        }, 500);
      }
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/country"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
